export const realisationsData = [
  {
    client: "Kevin Jehanno",
    description: "Coach sportif à Nantes",
    categories: ["Site vitrine", "Web Marketing"],
    techno: [
      "Gatsby",
      "React",
      "Prismic",
      "Netlify",
      "AWS",
      "Styled Components",
      "Javascript",
    ],
    date: "Février 2020",
    url: "/realisations/kevcoachingpro",
    content: "Le contenu ici",
    images: ["imageKevCoachingPro"],
    col: "col-sm-12 col-md-6 col-lg-4",
  },
  {
    client: "SLAP digital",
    description: "Agence de croissance digitale",
    categories: ["Site vitrine", "Web Marketing"],
    techno: [
      "Gatsby",
      "React",
      "WordPress API",
      "Netlify",
      "AWS",
      "Styled Components",
      "Javascript",
    ],
    date: "Octobre 2019",
    url: "/realisations/slap-digital",
    content: "Le contenu ici",
    images: ["imageSlap"],
    col: "col-sm-12 col-md-6 col-lg-8",
  },
  {
    client: "Happ-e 1 minute by Engie",
    description: "Fournisseur d'électricité et de gaz moins cher",
    categories: ["Site de souscription ", "Énergie"],
    techno: [
      "React",
      "PHP",
      "AWS API Gateaway",
      "AWS Lambda",
      "AWS DynamoDB",
      "Firebase",
      "Styled Components",
      "Javascript",
    ],
    date: "Juin 2019",
    url: "/realisations/happ-e-by-engie",
    content: "Le contenu ici",
    images: ["imageHappe"],
    imagesMobile: ["imageHappeMobile"],
    col: "col-sm-12 col-md-6 col-lg-8",
  },
  {
    client: "Le Collectif Engagés",
    description:
      "Regroupement de sociétés innovantes au service d’une économie éthique et sociale.",
    categories: ["Site vitrine"],
    techno: [
      "Gatsby",
      "React",
      "Prismic",
      "Netlify",
      "AWS",
      "Styled Components",
      "Javascript",
    ],
    date: "Août 2019",
    url: "/realisations/collectif-engages",
    content: "Le contenu ici",
    images: ["imageCollectif"],
    col: "col-sm-12 col-md-6 col-lg-4",
  },
  {
    client: "ReNezSens",
    description: "Réflexologue à Nantes",
    categories: ["Site vitrine", "Bien-être"],
    techno: [
      "Gatsby",
      "React",
      "Prismic",
      "Netlify",
      "Styled Components",
      "Javascript",
    ],
    date: "Juin 2019",
    url: "/realisations/renezsens",
    content: "Le contenu ici",
    images: ["imageRenezsens"],
    col: "col-sm-12 col-md-6 col-lg-4",
  },

  {
    client: "WorkUp",
    description:
      "Boite à outils pour les personnes en transition professionnelle",
    categories: ["Application web", "Emploi"],
    techno: [
      "Ruby On Rails",
      "PostgreSQL",
      "AWS S3",
      "JQuery",
      "BootStrap",
      "HTML5",
      "CSS3",
    ],
    date: "Septembre 2015",
    url: "/realisations/workup",
    content: "Le contenu ici",
    images: ["imageWorkUp"],
    col: "col-sm-12 col-md-6 col-lg-4",
  },
  {
    client: "Battlerite Pro",
    description: "Application de statistiques pour les joueurs de Battlerite",
    categories: ["Application web", "Jeu vidéo"],
    techno: ["React", "MongoDB", "Express.js", "Node.js", "Scss"],
    date: "Mai 2018",
    url: "/realisations/battlerite-pro",
    content: "Le contenu ici",
    images: ["imageBattlerite"],
    col: "col-sm-12 col-md-6 col-lg-4",
  },
  {
    client: "Portfolio",
    description: "Portfolio de Bertrand Signorelli, motion designer",
    categories: ["Portfolio", "Motion design"],
    techno: ["Wordpress", "Divi", "HTML5", "CSS3", "Javascript"],
    date: "Mai 2017",
    url: "/realisations/portfolio-bertrand",
    content: "Le contenu ici",
    images: ["imagePortfolio"],
    col: "col-sm-12 col-md-6 col-lg-4",
  },
];
