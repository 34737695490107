import React from "react";
import Link from "gatsby-link";
import Img from "gatsby-image";

import { realisationsData } from "../../data/realisations";

const Realisations = ({ data }) => {
  const imagesSource = item => {
    return [
      {
        ...data[`${item.imagesMobile[0]}`].childImageSharp.fluid,
        media: `(max-width: 768px)`
      },
      {
        ...data[`${item.images[0]}`].childImageSharp.fluid,
        media: `(min-width: 769px)`
      }
    ];
  };

  return (
    <section
      id="portfolio-vintage"
      className="portfolio portfolio-vintage portfolio-3col  portfolio-animation pb-40"
    >
      <div className="container">
        <div className="row heading heading-2 mb-70">
          <div className="col-sm-12 col-md-12 col-lg-12">
            <div className="subheading">Ils ont adoré</div>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-4">
            <h2>Nos réalisations</h2>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-6">
            <p>
              Voici quelques exemples de sites développés avec{" "}
              <i className="fas fa-heart fa-pulse" />
              <Link className="service-more" to="/realisations">
                <i className="fal fa-long-arrow-alt-right fa-2x" />
                <span>Voir nos dernières réalisations</span>
              </Link>
            </p>
          </div>
        </div>
      </div>
      <div className="container">
        <div id="portfolio-all" className="row">
          {realisationsData.slice(0, 2).map((item, index) => (
            <div key={index} className={`${item.col} portfolio-item`}>
              <div className="portfolio-item-box">
                <Link
                  className="portfolio-img"
                  to={item.url}
                  title={item.description}
                >
                  <Img
                    fluid={
                      item.imagesMobile
                        ? imagesSource(item)
                        : data[`${item.images[0]}`].childImageSharp.fluid
                    }
                    alt={item.client}
                  />
                  <div className="portfolio-hover">
                    <div className="portfolio-hover-content">
                      <div className="portfolio-meta">
                        <div className="portfolio-title">
                          <h4>{item.client}</h4>
                        </div>
                        <div className="portfolio-cat">
                          <span>
                            {item.techno.map((tech, index) => (
                              <span key={index}>{tech}</span>
                            ))}
                          </span>
                        </div>
                      </div>
                      <div className="portfolio-action">
                        <div className="portfolio-zoom-border">
                          <i className="far fa-plus" />
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Realisations;
