import React from "react";

const Studio = () => {
  return (
    <section id="counter1" className="counter counter-1">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-10 col-lg-4">
            <p className="text-right counter-bio">
              Azerty est un studio nantais de création de sites internet né de
              l'association entre trois expertises.
            </p>
          </div>
          <div className="col-sm-12 col-md-4 col-lg-2 offset-lg-1">
            <div className="counter-panel">
              <div className="counter-content">
                <i className="fal fa-desktop" />
                <div className="count-dis">développement web</div>
              </div>
            </div>
          </div>

          <div className="col-sm-12 col-md-4 col-lg-2">
            <div className="counter-panel">
              <div className="counter-content">
                <i className="fal fa-bullhorn" />
                <div className="count-dis">référencement</div>
              </div>
            </div>
          </div>

          <div className="col-sm-12 col-md-4 col-lg-2">
            <div className="counter-panel">
              <div className="counter-content">
                <i className="fal fa-chalkboard-teacher" />
                <div className="count-dis">analytics</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Studio;
