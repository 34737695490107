import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Banner from "../components/home/bloc-banner";
import Offre from "../components/home/bloc-offre";
import Studio from "../components/home/bloc-studio";
import Realisations from "../components/home/bloc-realisations";
import Temoignages from "../components/home/bloc-temoignages";
import Blog from "../components/home/bloc-blog";
import { temoignagesData } from "../data/temoignages";

const IndexPage = ({ data }) => {
  return (
    <Layout>
      <SEO
        title="Azerty Studio, agence web à Nantes"
        keywords={[
          `création de site internet Nantes`,
          `création de site web Nantes`,
          `Studio de développement Nantes`,
        ]}
      />
      <Banner imageBanner={data.imageBanner.childImageSharp.fluid} />
      <Studio />
      <Offre />
      <Realisations data={data} />
      <Temoignages data={data} temoignagesData={temoignagesData} />
      <Blog data={data} />
    </Layout>
  );
};

export const pageQuery = graphql`
  {
    allMarkdownRemark(
      filter: { frontmatter: { template: { eq: "blog" } } }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          frontmatter {
            date(formatString: "DD MMMM YYYY", locale: "fr")
            path
            title
            author
            linkedin
            categorie
            niveau
            image {
              childImageSharp {
                fluid(quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
    imageBanner: file(
      relativePath: { eq: "background/bannerHomeOriginal.jpg" }
    ) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    imageAnissa: file(relativePath: { eq: "testimonials/anissa.jpeg" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    imageNicolas: file(relativePath: { eq: "testimonials/nicolas.jpeg" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    imageSandrine: file(relativePath: { eq: "testimonials/sandrine.jpeg" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    imageBenjamin: file(relativePath: { eq: "testimonials/benjamin.jpeg" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    imageKevin: file(relativePath: { eq: "testimonials/kevin.jpg" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    imageMarieAthenais: file(
      relativePath: { eq: "testimonials/collectif.jpg" }
    ) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    imageBertrand: file(relativePath: { eq: "testimonials/bertrand.jpeg" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    imageWorkUp: file(relativePath: { eq: "portfolio/workup.jpg" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    imageBattlerite: file(relativePath: { eq: "portfolio/battlerite.jpg" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    imagePortfolio: file(relativePath: { eq: "portfolio/portfolio.jpg" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    imageRenezsens: file(relativePath: { eq: "portfolio/renezsens-1.png" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    imageHappe: file(relativePath: { eq: "portfolio/happ-e.png" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    imageHappeMobile: file(
      relativePath: { eq: "portfolio/happ-e-mobile.png" }
    ) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    imageSlap: file(relativePath: { eq: "portfolio/slap-1.png" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    imageCollectif: file(relativePath: { eq: "portfolio/collectif-1.png" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    imageKevCoachingPro: file(
      relativePath: { eq: "portfolio/kevin-home-crop.png" }
    ) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

export default IndexPage;
