import React from "react";
import Link from "gatsby-link";
import Img from "gatsby-image";

const Blog = ({ data }) => {
  const blogData = data.allMarkdownRemark.edges;
  return (
    <section
      id="blog-grid-3columns"
      className="blog blog-grid blog-grid-3columns"
    >
      <div className="container">
        <div className="row heading heading-2 heading-3 mb-30">
          <div className="col-sm-12 col-md-12 col-lg-12">
            <div className="subheading">Derniers articles</div>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-4">
            <h2>Notre Blog</h2>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-6">
            <p>
              Découvrez tous nos conseils et astuces pour développer votre
              activité en ligne : optimisation de votre site, référencement,
              analyse des performances, etc.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12">
            <div className="row">
              {blogData.map((blogPost, index) => {
                return (
                  <div key={index} className="col-sm-12 col-md-4 col-lg-4">
                    <div className="entry">
                      <div className="entry-featured">
                        <a href={blogPost.node.frontmatter.path}>
                          <Img
                            fluid={
                              blogPost.node.frontmatter.image.childImageSharp
                                .fluid
                            }
                            alt={blogPost.node.frontmatter.title}
                          />
                        </a>
                      </div>
                      {/* <div className="entry-date text-center">
                        <span>{blogPost.node.frontmatter.date}</span>
                      </div> */}
                      <div className="entry-title text-center">
                        <h3>
                          <a href={blogPost.node.frontmatter.path}>
                            {blogPost.node.frontmatter.title}
                          </a>
                        </h3>
                      </div>
                      {/* <div className="entry-meta text-center">
                        <span>By : </span>
                        <a href={blogPost.node.frontmatter.linkedin}>
                          {blogPost.node.frontmatter.author}
                        </a>
                      </div> */}
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12 text-center">
                <Link className="btn btn-ternary btn-hover" to="/blog">
                  Voir le Blog
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Blog;
