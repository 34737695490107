import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Img from "gatsby-image";

const SampleNextArrow = props => {
  const { onClick } = props;
  return (
    <i
      className="fal fa-long-arrow-alt-right fa-2x next-arrow"
      onClick={onClick}
    />
  );
};

const SamplePrevArrow = props => {
  const { onClick } = props;
  return (
    <i
      className="fal fa-long-arrow-alt-left fa-2x prev-arrow"
      onClick={onClick}
    />
  );
};

const Temoignages = ({ data, temoignagesData }) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    // autoplay: true,
    // autoplaySpeed: 8000,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true
        }
      }
    ]
  };

  return (
    <section id="testimonial2" className="testimonial testimonial-2 bg-gray">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-8 offset-lg-2">
            <div
              className="carousel owl-carousel"
              data-slide="1"
              data-slide-rs="1"
              data-autoplay="true"
              data-nav="false"
              data-dots="false"
              data-space="0"
              data-loop="true"
              data-speed="800"
              data-slider-id="1"
            >
              <Slider {...settings}>
                {temoignagesData.map((temoignage, index) => {
                  console.log("temoignage ", temoignage);
                  return (
                    <div key={index} className="testimonial-panel">
                      <div className="testimonial-content">
                        <i className="quote fas fa-quote-left" />
                        <p> {temoignage.content}</p>
                      </div>
                      <div className="testimonial-meta">
                        <div className="author-avatar">
                          {data &&
                            data[`${temoignage.picture}`].childImageSharp
                              .fluid && (
                              <Img
                                fluid={
                                  data[`${temoignage.picture}`].childImageSharp
                                    .fluid
                                }
                                alt={temoignage.author}
                              />
                            )}
                          {temoignage.testimonialPicture &&
                            temoignage.testimonialPicture.childImageSharp
                              .fluid && (
                              <Img
                                fluid={
                                  temoignage.testimonialPicture.childImageSharp
                                    .fluid
                                }
                                alt={temoignage.author}
                              />
                            )}
                        </div>
                        <div className="author-name">
                          <a
                            href={temoignage.linkedin}
                            title={temoignage.role}
                            target="_blank"
                            rel="noreferrer noopener"
                          >
                            {temoignage.author}
                          </a>
                        </div>
                        <div className="author-job">{temoignage.role}</div>
                      </div>
                    </div>
                  );
                })}
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Temoignages;
