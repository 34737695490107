export const temoignagesData = [
  {
    author: "Benjamin Blachère",
    content: `Notre agence de marketing digital avait besoin de refondre son site internet pour répondre à un triple objectif d'amélioration de son image, d'augmentation de sa visibilité et de génération de prospects. A cette fin, nous cherchions une solution robuste, moderne et nous donnant un maximum d'autonomie. Après avoir rencontré plusieurs agences web, notre choix s'est porté sur Azerty Studio et grand bien nous en a pris ! Ils ont bien su saisir nos enjeux, ont toujours été très disponibles et le site a été livré dans les temps. Nous sommes ravis du résultat. Merci à toute l'équipe !`,
    picture: "imageBenjamin",
    linkedin: "https://www.linkedin.com/in/benjaminblachere/",
    role: "Président de SLAP digital",
  },
  {
    author: "Kevin Jehanno",
    content: `Je profite de ce mail pour vous remercier pour le temps, l’énergie mise à contribution et la bienveillance de vos propos durant ces derniers mois. Je n’hésiterai pas une seconde à vous recommander ou refaire appel à vos services. Ma demande initiale a été parfaitement étudiée et je suis très satisfait du rendu final.`,
    picture: "imageKevin",
    linkedin: "https://www.instagram.com/kevcoachingpro/",
    role: "Coach sportif à Nantes",
  },
  {
    author: "Marie-Athénaïs Hénin",
    content: `Nous nous sommes présentées à Azerty Studio avec un schéma de site internet griffonné sur une feuille de papier. Ensemble nous avons fait le tri entre ce dont nous avions réellement besoin et ce qui était superflu. Résultat le site est à nos yeux tellement clair, lisible et agréable à consulter ! Nous recommandons Azerty aux novices qui rêvent d'un beau site mais ne savent pas par où commencer !`,
    picture: "imageMarieAthenais",
    linkedin:
      "https://www.linkedin.com/in/marie-ath%C3%A9na%C3%AFs-h%C3%A9nin-83712a8a/",
    role: "Co-fondatrice chez Collectif Engagés",
  },
  {
    author: "Sandrine Colle",
    content: `Merci à vous deux ! Après lecture du site, j'en suis fan. Il est très aéré, concis, clair et dégage aussi une certaine légèreté. J'aime vraiment beaucoup et j'ai hâte de le partager à mes proches !`,
    picture: "imageSandrine",
    linkedin: "https://www.linkedin.com/in/sandrine-colle-a749223b/",
    role: "Réflexologue et fondatrice de ReNezSens",
  },
  {
    author: "Bertrand Signorelli",
    content: `Produire notre site web avec Azerty Studio a été une belle
        expérience d'échange d'expertise. Ils ont été à l'écoute
        de nos attentes et besoins et ont su y répondre en
        proposant des solutions techniques pertinentes. Je sais
        déjà que je repasserai par eux la prochaine fois !`,
    picture: "imageBertrand",
    linkedin: "https://www.linkedin.com/in/bertrand-signorelli-88a89421/",
    role: "Motion designer et réalisateur",
  },
  {
    author: "Anissa Bennai",
    content: `Je suis super contente du travail effectué par Azerty
    Studio. Merci encore pour votre pédagogie qui m'a fait
    comprendre que l'utile peut aussi être beau. Je n'ai reçu
    que des compliments et le back office est super simple. Je
    recommande fortement !`,
    picture: "imageAnissa",
    linkedin: "https://www.linkedin.com/in/anissa-bennai/",
    role: "Co-fondatrice de WorkUp",
  },
];
