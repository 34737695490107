import React from "react";
import Link from "gatsby-link";

const Offre = () => {
  return (
    <section id="service1" className="service service-1 bg-gray">
      <div className="container">
        <div className="row heading heading-2 mb-60">
          <div className="col-sm-12 col-md-12 col-lg-12">
            <div className="subheading">Ce que nous proposons</div>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-4">
            <h2>Nos offres</h2>
          </div>
          <div className="col-sm-12 col-md-10 col-lg-6">
            <p>
              Nous créons des sites rapides, sécurisés et optimisés pour générer
              du trafic et des ventes grâce à des technologies modernes :
              GatsbyJs, ReactJS, AWS, WordPress API, etc.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 col-md-6 col-lg-4">
            <div className="service-panel">
              <div className="service-icon">
                <i className="fal fa-magic" />
              </div>
              <div className="service-content">
                <h3>Site vitrine</h3>
                <p>
                  Vous souhaitez faire connaître votre marque, vos produits ou
                  vos services&nbsp;? Nous concevons un site vitrine adapté à
                  vos besoins !
                </p>
                <Link
                  className="service-more"
                  to="/offres"
                  state={{ typeOffre: "vitrine" }}
                >
                  <i className="fal fa-long-arrow-alt-right fa-2x" />
                  <span>En savoir plus</span>
                </Link>
              </div>
            </div>
          </div>

          <div className="col-sm-12 col-md-6 col-lg-4">
            <div className="service-panel">
              <div className="service-icon">
                <i className="fal fa-shopping-cart" />
              </div>
              <div className="service-content">
                <h3>Site e-commerce</h3>
                <p>
                  Vous souhaitez vendre vos produits directement en ligne&nbsp;?
                  Nous concevons un site e-commerce comprenant un système de
                  paiement et de gestion des stocks.
                </p>
                <Link
                  className="service-more"
                  to="/offres"
                  state={{ typeOffre: "ecommerce" }}
                >
                  <i className="fal fa-long-arrow-alt-right fa-2x" />
                  <span>En savoir plus</span>
                </Link>
              </div>
            </div>
          </div>

          <div className="col-sm-12 col-md-12 col-lg-4">
            <div className="service-panel">
              <div className="service-icon">
                <i className="fal fa-pencil-ruler" />
              </div>
              <div className="service-content">
                <h3>Application web</h3>
                <p>
                  Vous avez un besoin de développement spécifique&nbsp;? Nous
                  concevons des applications web&nbsp;: plateforme de
                  réservation, tableau de bord, chatbot, etc.
                </p>
                <Link
                  className="service-more"
                  to="/offres"
                  state={{ typeOffre: "application" }}
                >
                  <i className="fal fa-long-arrow-alt-right fa-2x" />
                  <span>En savoir plus</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Offre;
