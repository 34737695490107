import React from "react";
import styled from "styled-components";
import Link from "gatsby-link";
import Img from "gatsby-image";

const Background = styled.section`
  position: relative;
`;

const Baseline = styled.p`
  color: #4f5054;
  line-height: 26px;
  font-size: 18px;
  width: 55%;
  @media (max-width: 767px) {
    width: 100%;
  }
`;

const Banner = ({ imageBanner }) => {
  return (
    <Background id="page-title" className="page-title home">
      <Img fluid={imageBanner} alt="Azerty Studio Banniere" />
      <div className="container-fluid bg-overlay">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12">
            <div className="title title-2">
              <div className="title-content">
                <div className="title-heading">
                  <h1>Azerty Studio</h1>
                </div>
                <Baseline>
                  Nous créons des sites internet, rapides, sécurisés et
                  optimisés pour booster votre activité
                </Baseline>
                <Link className="btn btn-secondary btn-hover" to="/contact">
                  Contactez-nous
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Background>
  );
};

export default Banner;
